import { defineStore } from "pinia";
import axios from 'axios'
import { useRuntimeConfig } from "nuxt/app";

export const useFavoriteStore = defineStore("favorite", {
	state: () => ({
		userFavoriteCollections: [],
		userFavoriteSearch: [],
	}),
	getters: {
		userFavoriteSearchGetter: (state) => () => state.userFavoriteSearch,
	},
	actions: {
		getVcrmAccountToken() {
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

		async fetchUserFavoriteSearches() {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';


			await axios.get(`${accountApiUrl}/favorite-searches`).then((res) => {
				this.userFavoriteSearch = res.data;
			}).catch(()=> null);
		},

		async fetchUserFavoriteCollections() {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';


			const res = await axios.get(`${accountApiUrl}/favorite-collections`).catch(()=> null);

			this.userFavoriteCollections = res.data;
		},

		async fetchUserFavoriteListingSearch(listingId: number) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';


			return await axios.get(`${accountApiUrl}/favorites/${listingId}/exists`).catch(()=> null);
		},

		async addFavoriteSearch(data: { [key: string]: any }) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';


			return await axios.post(`${accountApiUrl}/favorite-searches`, data).then(async (data) => {
				await this.fetchUserFavoriteSearches();
				return data;
			}).catch(()=> null);
		},

		async addUserFavoriteNewCollection(collectionName: string) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';

			axios.defaults.headers.common["Content-Type"] = "application/json";
			axios.defaults.headers['application-platform'] = 'web';


			return await axios.post(`${accountApiUrl}/favorite-collections`, {
				name: collectionName,
				color: "#0B5ED7",
			});

		},

		async addUserFavoriteListing(collectionId: number, listingId: number) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';

			axios.defaults.headers.common["Content-Type"] = "application/json";
			axios.defaults.headers['application-platform'] = 'web';


			return await axios.post(`${accountApiUrl}/favorites`, {
				collection_id: collectionId,
				listing_id: listingId,
			}).catch(()=> null);

		},

		async checkExistFavoriteSearch(data: { url: string }) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';


			return await axios.post(`${accountApiUrl}/favorite-searches/exists`, data).catch(()=> null);
		},

		async removeFavoriteSearch(id: number) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';


			return await axios.delete(`${accountApiUrl}/favorite-searches/${id}`).then(async (res) => {
				await this.fetchUserFavoriteSearches();
				return res;
			}).catch(()=> null);
		},

		async removeUserFavoriteListing(listingId: number) {
			const config = useRuntimeConfig();
			const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}
			const accountApiUrl = config.public.vcrmAccountApiUrl as string

			axios.defaults.headers.common["Authorization"] = "Bearer " + vue_cookie;
			axios.defaults.headers['application-platform'] = 'web';

			return await axios.delete(`${accountApiUrl}/favorites/${listingId}`).catch(()=> null);

		}
	},
});
